const Description = () => {
  return (
    <div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate et ad
        inventore cupiditate alias corporis veniam. Sed expedita at sequi
        magnam, ratione earum fuga perspiciatis odio consequuntur quidem iure
        laboriosam consequatur minus. Aut et natus, doloribus autem eos sint ea
        temporibus placeat delectus quidem facilis?
      </p>
      <p className="mt-3">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate et ad
        inventore cupiditate alias corporis veniam. Sed expedita at sequi
        magnam, ratione earum fuga perspiciatis odio consequuntur quidem iure
        laboriosam consequatur minus. Aut et natus, doloribus autem eos sint ea
        temporibus placeat delectus quidem facilis?
      </p>
    </div>
  );
};

export default Description;
